/* eslint-disable */

<template>
  <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
    <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
      <div class="vx-col w-full">
        <vx-card class="mb-4">
          <div class="vx-row">
            <div class="vx-col w-2/12 center-2"></div>
            <div class="vx-col w-2/12 center-2">
              <div class="line-w-1"></div>
            </div>
            <div class="vx-col w-2/12 center-2">
              <div class="tenth-block">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image mb-2"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/switch.png"
                    width="120"
                  />
                </div>
                <p class="font-bold text-center" style="color: #0053ff">VLAN ID: 13 | DMZ</p>
                <h4 class="font-bold text-center">DELL MAN<br />GIG SWITCH</h4>
              </div>
            </div>
            <div class="vx-col w-6/12 center-2">
              <vue-draggable-container
                id="topContainer"
                class="drag-container multiple-drop flex justify-center items-center gap-4 p-5 main-border rounded-lg"
                style="background: #040915; min-width: 15vw"
              >
                <div class="dragBlock topContainer cursor-pointer tenth-block drag-thing-color">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image mb-2"
                      style="filter: invert()"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/n-icon.png"
                      width="40"
                    />
                  </div>
                  <h4 class="font-bold text-center" style="color: #fff">NGINX</h4>
                  <h4 class="font-bold text-center" style="color: #fff">WEB SERVER</h4>
                </div>
                <div class="dragBlock topContainer cursor-pointer tenth-block drag-thing-color">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image mb-2"
                      style="filter: invert()"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/windows-server.png"
                      width="40"
                    />
                  </div>
                  <h4 class="font-bold text-center" style="color: #fff">MSDNS</h4>
                  <h4 class="font-bold text-center" style="color: #fff">DNS SERVER</h4>
                </div>
              </vue-draggable-container>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-2/12 center-2">
              <div class="tenth-internet-block-2">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/router-distributor.png"
                    width="60"
                  />
                </div>
                <h3 class="text-center font-bold">UNIFI<br />ROUTER</h3>
              </div>
            </div>
            <div class="vx-col w-2/12 center-2">
              <div class="tenth-firewall-block-2">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/PA220.png"
                    width="120"
                  />
                </div>
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/paloalto-logo.png"
                    width="120"
                  />
                </div>
                <h3 class="text-center font-bold">NEXT-GEN FW</h3>
              </div>
              <div class="line-w-0"></div>
              <div class="line-internet-up"></div>
            </div>
            <div class="vx-col w-2/12 center-2"></div>
            <div class="vx-col w-6/12 center-2"></div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-2/12 center-2"></div>
            <div class="vx-col w-2/12 center-2">
              <div class="line-w-1"></div>
            </div>
            <div class="vx-col w-2/12 center-2">
              <div class="tenth-block">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image mb-2"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/switch.png"
                    width="120"
                  />
                </div>
                <p class="font-bold text-center" style="color: #0053ff">VLAN ID: 14 | CORP</p>
                <h4 class="font-bold text-center">DELL MAN<br />GIG SWITCH</h4>
              </div>
            </div>
            <div class="vx-col w-6/12 center-2">
              <vue-draggable-container
                id="bottomContainer"
                class="drag-container multiple-drop flex justify-center items-center gap-4 p-5 main-border rounded-lg"
                style="background: #040915; min-width: 15vw"
              >
                <div class="dragBlock bottomContainer cursor-pointer tenth-block drag-thing-color">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image mb-2"
                      style="filter: invert()"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/microsoft-logo.png"
                      width="40"
                    />
                  </div>
                  <h4 class="font-bold text-center" style="color: #fff">MS ACTIVE</h4>
                  <h4 class="font-bold text-center" style="color: #fff">DIRECTORY SERVER</h4>
                </div>
                <div class="dragBlock bottomContainer cursor-pointer tenth-block drag-thing-color">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image mb-2"
                      style="filter: invert()"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/441663084428263424/google-drive-logo.png"
                      width="40"
                    />
                  </div>
                  <h4 class="font-bold text-center" style="color: #fff">SYNOLOGY</h4>
                  <h4 class="font-bold text-center" style="color: #fff">NFS FILE SERVER</h4>
                </div>
              </vue-draggable-container>
            </div>
          </div>
        </vx-card>
        <div
          class="border-solid rounded-lg p-1 vx-card mb-4"
          :class="
            isCorrect[1] === true ? 'border-success' : 'border-primary' && isCorrect[1] === false ? 'border-danger' : 'border-primary'
          "
        >
          <vs-collapse-item class="p-0">
            <div slot="header" class="p-0 flex items-center">
              <feather-icon v-if="isCorrect[1] === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-success" />
              <feather-icon v-if="isCorrect[1] === false" icon="XCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-danger" />
              <h3 class="font-light">Scan Findings Listing 1</h3>
            </div>
            <div class="vx-row">
              <div class="vx-col w-3/12">
                <vue-draggable-container
                  id="input1"
                  class="dz-answer drag-container flex justify-center items-center gap-4 p-3 main-border rounded-lg"
                  style="background: #040915"
                >
                </vue-draggable-container>
              </div>
              <div class="vx-col w-6/12">
                <h5 class="mb-2">Select The False Positive</h5>
                <vs-radio class="w-full mb-1" v-model="falsePositive[1]" vs-name="select1" vs-value="0"
                  >Critical (10.0) 12209 Security Update for Microsoft Windows (889430)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[1]" vs-name="select1" vs-value="1"
                  >Critical (10.0) 12908 Microsoft Windows Task Scheduler Remote Overflow (865400)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[1]" vs-name="select1" vs-value="2"
                  >Critical (10.0) 13209 SMB Vulnerability Could Result in Remote File Access (884390)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[1]" vs-name="select1" vs-value="3"
                  >Critical (10.0) 15546 Samba 3.x < 3.8.4 / 3.6.14 / 3.7.16 RPC Multiple Buffer Overflows (835732)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[1]" vs-name="select1" vs-value="4"
                  >Critical (10.0) MS10-061: Vulnerability in Print Spooler Service Could Allow Remote Code Execution (2347290)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select1" vs-value="5">No False Positive </vs-radio>
              </div>
              <div class="vx-col w-3/12">
                <h5 class="mb-2">Results Generated</h5>
                <v-select placeholder="Select an option" :options="resultOptions" v-model="resultSelected[1]" />
              </div>
            </div>
          </vs-collapse-item>
        </div>
        <div
          class="border-solid rounded-lg p-1 vx-card mb-4"
          :class="
            isCorrect[2] === true ? 'border-success' : 'border-primary' && isCorrect[2] === false ? 'border-danger' : 'border-primary'
          "
        >
          <vs-collapse-item class="p-0">
            <div slot="header" class="p-0 flex items-center">
              <feather-icon v-if="isCorrect[2] === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-success" />
              <feather-icon v-if="isCorrect[2] === false" icon="XCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-danger" />
              <h3 class="font-light">Scan Findings Listing 2</h3>
            </div>
            <div class="vx-row">
              <div class="vx-col w-3/12">
                <vue-draggable-container
                  id="input2"
                  class="dz-answer drag-container flex justify-center items-center gap-4 p-3 main-border rounded-lg"
                  style="background: #040915"
                >
                </vue-draggable-container>
              </div>
              <div class="vx-col w-6/12">
                <h5 class="mb-2">Select The False Positive</h5>
                <vs-radio class="w-full mb-1" v-model="falsePositive[2]" vs-name="select2" vs-value="1"
                  >MS10-061: Vulnerability in Print Spooler Service Could Allow Remote Code Execution (2347290)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[2]" vs-name="select2" vs-value="2"
                  >Critical (8.6) 088744 Ubuntu 6.04/6.10 / 6.06 LTS : Buffer overrun in enscript before 1.6.4 (CVE-2008-3345)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[2]" vs-name="select2" vs-value="3"
                  >Critical (9.2) 089876 Ubuntu 6.04/6.10 / 6.06 LTS : PHP and Javascript Vulnerabilities (CVE-2018-3387)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[2]" vs-name="select2" vs-value="4"
                  >Critical (9.6) 087644 Ubuntu 6.04/6.10 / 6.06 LTS : GNUPG Vulnerability and PHP script before 1.7.4 (CVE-2014-3945)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[2]" vs-name="select2" vs-value="5"
                  >Critical (10.0) 087776 Ubuntu 6.04/6.10 / 6.06 LTS : CSS and AJAX script can be exploit (CVE-2008-3345)
                </vs-radio>
              </div>
              <div class="vx-col w-3/12">
                <h5 class="mb-2">Results Generated</h5>
                <v-select placeholder="Select an option" :options="resultOptions" v-model="resultSelected[2]" />
              </div>
            </div>
          </vs-collapse-item>
        </div>
        <div
          class="border-solid rounded-lg p-1 vx-card mb-4"
          :class="
            isCorrect[3] === true ? 'border-success' : 'border-primary' && isCorrect[3] === false ? 'border-danger' : 'border-primary'
          "
        >
          <vs-collapse-item class="p-0">
            <div slot="header" class="p-0 flex items-center">
              <feather-icon v-if="isCorrect[3] === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-success" />
              <feather-icon v-if="isCorrect[3] === false" icon="XCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-danger" />
              <h3 class="font-light">Scan Findings Listing 3</h3>
            </div>
            <div class="vx-row">
              <div class="vx-col w-3/12">
                <vue-draggable-container
                  id="input3"
                  class="dz-answer drag-container flex justify-center items-center gap-4 p-3 main-border rounded-lg"
                  style="background: #040915"
                >
                </vue-draggable-container>
              </div>
              <div class="vx-col w-6/12">
                <h5 class="mb-2">Select The False Positive</h5>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="0"
                  >WARNING (1.0.2) System Hashing & Encryption: Force strong hashing algorithm and complex key stored on the computer:
                  ENABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="1"
                  >INFORM (1.2.8) Network Access: Deny Anonymous Network Enumeration of SAM accounts: ENABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="2"
                  >INFORM (1.2.4) Network Access: Deny Anonymous Scans of SAM accounts and network shares: ENABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="3"
                  >INFORM (1.8.0) Network Access: Deny Random Sharing of Local User Accounts: ENABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="4"
                  >INFORM (1.6.2) Network Access: Allow Everyone Permissions to Disabled Users in SAM: DISABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="5"
                  >INFORM (1.6.8) Network Access: Sharing and Security Models for Local Accounts in AD set to CLASSIC: ENABLED
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[3]" vs-name="select3" vs-value="6">No False Positive </vs-radio>
              </div>
              <div class="vx-col w-3/12">
                <h5 class="mb-2">Results Generated</h5>
                <v-select placeholder="Select an option" :options="resultOptions" v-model="resultSelected[3]" />
              </div>
            </div>
          </vs-collapse-item>
        </div>
        <div
          class="border-solid rounded-lg p-1 vx-card mb-4"
          :class="
            isCorrect[4] === true ? 'border-success' : 'border-primary' && isCorrect[4] === false ? 'border-danger' : 'border-primary'
          "
        >
          <vs-collapse-item class="p-0">
            <div slot="header" class="p-0 flex items-center">
              <feather-icon v-if="isCorrect[4] === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-success" />
              <feather-icon v-if="isCorrect[4] === false" icon="XCircleIcon" svgClasses="w-8 h-8 mr-2 self-center text-danger" />
              <h3 class="font-light">Scan Findings Listing 4</h3>
            </div>
            <div class="vx-row">
              <div class="vx-col w-3/12">
                <vue-draggable-container
                  id="input4"
                  class="dz-answer drag-container flex justify-center items-center gap-4 p-3 main-border rounded-lg"
                  style="background: #040915"
                >
                </vue-draggable-container>
              </div>
              <div class="vx-col w-6/12">
                <h5 class="mb-2">Select The False Positive</h5>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="0"
                  >High (8.2) 12209 Security Update for BIND Server (CVE 845321)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="1"
                  >High (8.4) 12922 Buffer overflow that can lead to a server crash (CVE 865400)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="2"
                  >High (8.4) 12943 Buffer overflow that can lead to a remote execution (CVE 865466)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="3"
                  >Medium (7.2) 13254 BIND Vulnerability Could Result in Remote File Access (CVE 884398)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="4"
                  >Medium (7.2) 13268 BIND Vulnerability Could Result in a DoS Attack (CVE 884376)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="5"
                  >High (8.3) 132287 BIND Vulnerability can trigger an assertion failure that results in denial of service (CVE 883342)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="6"
                  >Critical (10.0) MS10-064: Vulnerability in Print Spooler Service Could Allow Remote Code Execution (CVE 2347765)
                </vs-radio>
                <vs-radio class="w-full mb-1" v-model="falsePositive[4]" vs-name="select4" vs-value="7">No False Positive </vs-radio>
              </div>
              <div class="vx-col w-3/12">
                <h5 class="mb-2">Results Generated</h5>
                <v-select placeholder="Select an option" :options="resultOptions" v-model="resultSelected[4]" />
              </div>
            </div>
          </vs-collapse-item>
        </div>
      </div>
    </div>
  </vue-sortable>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import draggable from 'vuedraggable';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      resultOptions: ['Credentialed', 'Non-Credentialed', 'Compliance'],

      falsePositive: {
        1: null,
        2: null,
        3: null,
        4: null,
      },

      resultSelected: {
        1: null,
        2: null,
        3: null,
        4: null,
      },

      isCorrect: {
        1: null,
        2: null,
        3: null,
        4: null,
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      const draggingItem = x.data.source;
      if (overContainer && overContainer.children) {
        if (!draggingItem.className.includes(overContainer.id) && !overContainer.className.includes('dz-answer')) {
          x.cancel();
        }
        if (
          overContainer.children.length > 0 &&
          overContainer.id !== sourceContainer.id &&
          !overContainer.className.includes('multiple-drop')
        ) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (
        this.falsePositive[1] === '3' &&
        this.resultSelected[1] === 'Non-Credentialed' &&
        document.getElementById('input1').textContent === 'SYNOLOGYNFS FILE SERVER'
      ) {
        totalScore++;
        this.isCorrect[1] = true;
      } else {
        this.isCorrect[1] = false;
      }

      if (
        this.falsePositive[2] === '1' &&
        this.resultSelected[2] === 'Credentialed' &&
        document.getElementById('input2').textContent === 'NGINXWEB SERVER'
      ) {
        totalScore++;
        this.isCorrect[2] = true;
      } else {
        this.isCorrect[2] = false;
      }

      if (
        this.falsePositive[3] === '6' &&
        this.resultSelected[3] === 'Compliance' &&
        document.getElementById('input3').textContent === 'MS ACTIVEDIRECTORY SERVER'
      ) {
        totalScore++;
        this.isCorrect[3] = true;
      } else {
        this.isCorrect[3] = false;
      }

      if (
        this.falsePositive[4] === '6' &&
        this.resultSelected[4] === 'Non-Credentialed' &&
        document.getElementById('input4').textContent === 'MSDNSDNS SERVER'
      ) {
        totalScore++;
        this.isCorrect[4] = true;
      } else {
        this.isCorrect[4] = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }
      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    draggable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wordBank {
  z-index: 1;
}

.center-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #000;
}

.tenth-image-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tenth-secure-network-block {
  position: relative;
  z-index: 99;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0px;
  padding: 5px;
  background-color: #9b59b6;
}

.tenth-secure-workstation-block {
  position: relative;
  z-index: 99;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0px;
  padding: 5px;
  background-color: #e74c3c;
}

.line-internet-up {
  position: absolute;
  width: 4px;
  height: 321px;
  background-color: #fff;
}

.line-w-0 {
  position: absolute;
  width: 12vw;
  height: 4px;
  margin-left: -11vw;
  background-color: #fff;
}

.line-w-1 {
  position: absolute;
  width: 38vw;
  margin-left: 38vw;
  height: 4px;
  background-color: #fff;
}

.tenth-firewall-block-2 {
  z-index: 1;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  padding: 15px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  background-color: #ec6d3e;
  box-shadow: 1px 1px 3px 0 #272727;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 0.5rem;
}

.tenth-internet-block-2 {
  z-index: 1;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px 20px 10px 20px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  background-color: #0053ff;
  box-shadow: 1px 1px 3px 0 #272727;
  border-radius: 0.5rem;
  -o-object-fit: fill;
  object-fit: fill;
}

.tenth-block {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 165px;
  padding: 10px;
  border: 1px solid #181d2a;
  background-color: #040915;
  border-radius: 0.5rem;
  -o-object-fit: fill;
  object-fit: fill;
}

.drag-thing-color {
  background: #0053ff;
  min-height: 140px;
  background-repeat: no-repeat;
  background-position: center;
}

.drag-container {
  min-width: 155px;
  min-height: 155px;
  z-index: 1;
}

button.vs-tabs--btn {
  color: #ffffff;
}
</style>
